<template>
  <a-layout-content>
    <div class="touchless-loading" v-if="loading">
      <span class="loading-spin"></span>
    </div>
    <div style="background:#FFFFFF;" class="touchless-checkout mb-10">
      <a-card :bordered="false">
        <div class="touchless-img-product">
          <img
            :src="product.ProductImage"
            :alt="product.ProductName"
            referrerpolicy="no-referrer"
            onerror="if (this.src != '/img/img-placeholder.png') this.src = '/img/img-placeholder.png';"
          />
        </div>
        <a-card-meta>
          <template slot="title">
            <p class="m-0 font-16">{{ product.ProductName }}</p>
          </template>
          <template slot="description">
            <span class="text-red-text font-16">{{
              product.ProductPrice | currency
            }}</span>
          </template>
        </a-card-meta>
      </a-card>
    </div>
    <div style="background:#FFFFFF;" class="touchless-checkout-footer">
      <a-card :bordered="false">
        <a-row>
          <a-col :span="12"><p class="m-0">Total</p></a-col>
          <a-col :span="12" class="text-right">
            <span class="text-red-text font-16">{{
              product.ProductPrice | currency
            }}</span>
          </a-col>
        </a-row>
      </a-card>
      <a-button
        class="font-16 text-white"
        :style="
          `background-color: ${style.primaryColor};border-color: ${style.primaryColor}`
        "
        @click="handleSubmit"
      >
        Bayar Dengan QRIS
      </a-button>
    </div>
  </a-layout-content>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Checkout",
  data() {
    return {
      style: JSON.parse(localStorage.getItem("defaultStyle")),
      loading: false,
      product: {}
    };
  },
  mounted() {
    this.getDetailData();
  },
  methods: {
    ...mapActions(["showQr"]),
    getDetailData() {
      this.product = JSON.parse(localStorage.getItem("productDetail"));
    },
    async handleSubmit() {
      this.$analytics.logEvent("touchless_catalog_show_qris");

      const payload = {
        TID: localStorage.getItem("productTid"),
        BarCode: this.product.BarCode
      };
      try {
        this.loading = true;
        await this.showQr(payload);
        this.$router.push("/success");
      } catch (error) {
        this.$message.error("Pembayaran gagal", 10);
        throw error;
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
