<template>
  <a-layout-content class="touchless-default-layout">
    <div class="touchless-container">
      <a-card :bordered="false" class="text-center pl-16 pr-16">
        <img :src="image" alt="VM Not Found" />
        <h3 class="mb-16">{{ title }}</h3>
        <p>{{ information }}</p>
        <router-link to="/" class="text-cyan font-12">
          Back to Home Page
        </router-link>
      </a-card>
    </div>
  </a-layout-content>
</template>

<script>
export default {
  name: "notFoundPage",
  data() {
    return {
      image: "/img/img-placeholder.png",
      title: "",
      information: ""
    };
  },
  mounted() {
    const vmStatusData = JSON.parse(localStorage.getItem("vmStatus"));

    if (vmStatusData.status === false) {
      this.title = "Mesin Vending Offline";
      this.information =
        "Maaf bikin kamu jadi ngga nyaman, kamu bisa coba lain kali ya!";
      this.image = "/img/illus-vm-offline.png";
    } else {
      this.title = "Kode VM Tidak Ditemukan";
      this.information =
        "Pastikan kode pada mesin vending yang kamu masukan sudah sesuai ya!";
      this.image = "/img/illus-vm-salah.png";
    }
  }
};
</script>
