<template>
  <a-layout-content>
    <div class="touchless-loading" v-if="loading">
      <span class="loading-spin"></span>
    </div>
    <div class="touchless-home-content">
      <a-card :bordered="false">
        <h1 class="mb-10">
          Mau jajan di mesin vending tapi takut sentuhan sama layarnya?
        </h1>
        <p>
          Cari dan masukin kode unik yang ada di bagian bawah mesin vending. Ada
          di samping icon sinyal ya! :)
        </p>
        <div class="form-group">
          <label class="mb-10">Kode VM</label>
          <a-input
            size="large"
            placeholder="Contoh: SKN02***"
            v-model="vmCode"
          />
        </div>
        <p class="touchless-code-link mb-20">
          <span @click="showDrawer">Belum nemu kode uniknya? Klik disini</span>
        </p>
        <a-button
          type="warning"
          class="ant-btn-warning font-16 touchless-home-button mt-20"
          @click="checkVm"
          :disabled="!vmCode"
        >
          Lanjut
        </a-button>
      </a-card>
    </div>
    <a-drawer
      class="touchless-drawer-bottom text-center"
      placement="bottom"
      :closable="false"
      :visible="visible"
      height="580"
      @close="onClose"
    >
      <a-button class="p-0 touchless-drawer-close" @click="onClose">
        <img class="mb-20" src="../../public/img/close.png" alt="Close" />
      </a-button>
      <div class="touchless-drawer-content">
        <div class="touchless-drawer-card pt-24">
          <h3 class="font-20 mb-40">
            Udah ketemu sekarang kodenya?<br />Maaf buatmu kebingungan :)
          </h3>
          <img
            class="mb-40"
            src="../../public/img/img-vm-code.png"
            alt="VM Code"
          />
        </div>
      </div>
    </a-drawer>
  </a-layout-content>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Home",
  data() {
    return {
      loading: false,
      vmCode: "",
      visible: false
    };
  },
  mounted() {
    localStorage.clear();
  },
  methods: {
    ...mapActions(["getVmData"]),
    async checkVm() {
      try {
        this.loading = true;
        const data = await this.getVmData({ deviceCode: this.vmCode });

        if (data.status === 200) {
          this.$router.push(`${this.vmCode}?tracking=homepage`);
        }
      } catch (error) {
        this.$router.push("vm-not-found");
        localStorage.setItem(
          "vmStatus",
          JSON.stringify({
            status: undefined
          })
        );
      } finally {
        this.loading = false;
      }
    },
    showDrawer() {
      this.visible = true;
    },
    onClose() {
      this.visible = false;
    }
  }
};
</script>
