<template>
  <div id="app" class="touchless-atlas">
    <AppLayout>
      <router-view />
    </AppLayout>
  </div>
</template>
<script>
export default {
  name: "App"
};
</script>
