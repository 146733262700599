export const getDynamicStyle = code => {
  const vmCode = code;

  let data = {};
  if (vmCode.toUpperCase() == "SKN") {
    data = {
      imgLogo: "img/logo-color-atlas.png",
      primaryColor: "#F53240"
    };
  } else if (vmCode.toUpperCase() == "SKN02RBTG") {
    data = {
      imgLogo: "img/logo-color-stickearn.png",
      primaryColor: "#682DAA"
    };
  } else if (vmCode.slice(0, 3).toLowerCase() === "jxb") {
    data = {
      imgLogo: "img/logo-jxb-black.png",
      primaryColor: "#fc351c"
    };
  } else {
    data = {
      imgLogo: "img/logo-color-atlas.png",
      primaryColor: "#F53240"
    };
  }

  return data;
};
