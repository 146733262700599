import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import Antd from "ant-design-vue";
import VueCurrencyFilter from "vue-currency-filter";
import AppLayout from "@/layouts/AppLayout";

import "ant-design-vue/dist/antd.css";
import "./assets/scss/main.scss";

// Firebase
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: `${process.env.VUE_APP_FIREBASE_API_KEY}`,
  authDomain: `${process.env.VUE_APP_FIREBASE_AUTH_DOMAIN}`,
  databaseURL: `${process.env.VUE_APP_FIREBASE_DATABASE_URL}`,
  projectId: `${process.env.VUE_APP_FIREBASE_PROJECT_ID}`,
  storageBucket: `${process.env.VUE_APP_FIREBASE_STORAGE_BUCKET}`,
  messagingSenderId: `${process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID}`,
  appId: `${process.env.VUE_APP_FIREBASE_APP_ID}`,
  measurementId: `${process.env.VUE_APP_FIREBASE_MEASUREMENT_ID}`
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

// alias
Vue.prototype.$analytics = firebase.analytics();

Vue.component("AppLayout", AppLayout);
Vue.config.productionTip = false;
Vue.use(Antd);
Vue.use(VueCurrencyFilter, {
  symbol: "Rp",
  thousandsSeparator: ".",
  fractionCount: 0,
  fractionSeparator: ",",
  symbolPosition: "front",
  symbolSpacing: true,
  avoidEmptyDecimals: undefined
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
